<template>
  <div>
    <b-row class="">
      <b-col lg="2" md="4" sm="4" class="text-right mb-0 col-xs-2-12">
        <a-form :form="form"  @submit.prevent="search" layout="inline">
          <a-input-search v-model="q" placeholder="Search Media" @search="search"></a-input-search>
        </a-form>
      </b-col>
      <b-col lg="2" md="4" sm="2" class="text-right mb-0 col-xs-2-12">
        <a-form-model-item
          class="mb-0"
          :label-col="{ span: 10 }"
          :wrapper-col="{ span: 6 }">
          <a-select
            :default-value="0"
            style="width: 250px"
            @change="selectPortal"
            v-bind:value="portal_id">
            <a-select-option :value="''"> All Portal </a-select-option>
            <a-select-option v-for="portal in portalData" :key="portal.id" :value="portal.id"> {{portal.title}} </a-select-option>
          </a-select>
        </a-form-model-item>
      </b-col>
    </b-row>
    <a-tabs :animated="false" v-model="mediaTab" default-active-key="library">
      <a-tab-pane key="library" tab="Library">
        <b-row no-gutters>
          <b-col lg="8" class="bg-white">
            <div :class="$style.media__gallery">
              <div :class="$style.items">
                <div :class="$style.item" v-for="item in fileList.filter((file) => file.status !== 'done')" :key="item.uid">
                  <div :class="[
                    {
                      [$style.itemContent]: true
                    }]">
                    <div :class="$style.itemControl">
                      <div :class="$style.itemControlContainer">
                        <a-progress :stroke-width="3" :show-info="false" :status="item.status === 'error' ? 'exception' : 'active'" :percent="item.percent"></a-progress>
                        <!-- <p class="text-white text-capitalize small">{{ item.status }}</p> -->
                      </div>
                    </div>
                    <img :src="`${item.thumbUrl}`" :alt="item.alt" />
                  </div>
                </div>
                <div :class="$style.item" v-for="(item, index) in mediaData" :key="index">
                  <div :class="[
                    { [$style.itemContent]: true },
                    { [$style.selected] : checkedMedias.find(media => media.id === item.id) }
                  ]"
                    @click="(e) => selectMedia(e, item, index)">
                    <img :src="`${item.url}?tr=w-184,h-184`" :alt="item.alt" />
                  </div>
                </div>
                <div class="row justify-content-md-center w-100" v-if="medias.length" :key="'but4k80i4d48'">
                  <div class="col col-lg" >
                    <button :disabled="loading" type="button" class="btn btn-outline-primary col-md-auto btn-block" @click="fetch">
                      {{ !loading ? 'Load More' : 'loading ...'}}
                    </button>
                  </div>
                </div>
                <!-- <infinite-loading :identifier="page" :class="$style.loading" @infinite="fetch"></infinite-loading> -->
              </div>

            </div>
          </b-col>
          <b-col lg="4" class="p-4">
            <div v-if="Object.keys(selectedMedia).length">
              <h6>Media Detail</h6>
              <b-row no-gutters class="border-bottom pb-3 mb-3">
                <b-col cols="4">
                  <img :src="`${selectedMedia.url}?tr=w-80`" :srcset="`${selectedMedia.url}?tr=w-80 1x, ${selectedMedia.url}?tr=w-160 2x`" class="w-100" alt=""/>
                  <a :href="`${selectedMedia.url ? selectedMedia.url : ''}`" target="_blank">Open full image</a>
                </b-col>
                <b-col cols="8" class="pl-2">
                  <p class="small mb-0">ID: <span class="text-secondary">#{{ selectedMedia.id }}</span></p>
                  <p class="mb-0 font-weight-bold">{{ selectedMedia.file }}</p>
                  <p class="mb-0 text-muted small">
                    {{ selectedMedia.created_at | moment("D MMM YYYY, h:mm A") }}<br />
                    {{ selectedMedia.byte ? bytesToSize(selectedMedia.byte) : '' }}
                  </p>
                  <a href="javascript:;" class="text-danger small" @click="deleteMedia(selectedMedia.id)">Delete</a>
                  <!-- <a href="javascript:;" class="text-primary small ml-2">Edit</a> -->
                </b-col>
              </b-row>
              <MainForm v-on:submit="updateMedia" />
            </div>
          </b-col>
        </b-row>
      </a-tab-pane>
      <a-tab-pane key="upload" tab="Upload">
        <b-row>
          <b-col>
            <div :class="$style.media__uploader" class="p-3 bg-white position-relative">
              <a-upload-dragger
                :show-upload-list="true"
                listType="picture-card"
                :file="mediaFiles"
                accept="image/png, image/jpeg"
                :customRequest="uploadMedia"
                name="media"
                :multiple="true"
                @change="mediaUploaderChanged"
              >
                <p class="ant-upload-drag-icon">
                  <a-icon type="inbox" />
                </p>
                <p class="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p class="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                  band files
                </p>
              </a-upload-dragger>
            </div>
          </b-col>
        </b-row>
      </a-tab-pane>
    </a-tabs>
    <div v-if="type === 'popup'" class="bg-white text-right border-top p-2">
      <b-button variant="primary" :disabled="!Object.keys(selectedMedia).length" @click="insertMedia">
        {{ buttonText }}
      </b-button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'
import apiClient from '@/services/axios'
import MainForm from './form'

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    noControlPick: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'popup',
    },
    buttonText: {
      type: String,
      default: 'Insert to Post',
    },
  },
  data() {
    return {
      form: null,
      q: '',
      selectedMedia: {},
      mediaData: [],
      portalData: [],
      checkedMedias: this.current,
      mediaFiles: [],
      fileList: [],
      mediaTab: 'library',
      lastMediaIndex: null,
      page: 1,
    }
  },
  created() {
    const vm = this
    vm.page = 1
    vm.portal_id = vm.settings.activePortal
    vm.$store.dispatch('portal/FETCH', {
      sort_column: 'domain',
      sort_value: 'asc',
      perPage: 1000,
    }).then((res) => {
      vm.portalData = res.data.data
    })
    this.fetch()
  },
  computed: {
    ...mapState(['settings']),
    ...mapState('media', {
      medias: state => state.data,
    }),
    loading() {
      return this.$store.state.media.loading
    },
  },
  methods: {
    search() {
      const vm = this
      vm.page = 1
      vm.$store.dispatch('media/FETCH', {
        page: vm.page,
        perPage: 30,
        q: vm.q,
        portal_id: vm.portal_id,
        sort_column: 'id',
        sort_value: 'desc',
      }).then((res) => {
        if (res.data.data.length) {
          vm.mediaData = vm.medias
          vm.page++
        }
        // vm.page++

        // if ($state) {
        //   if (res.data.data && res.data.data.length) {
        //     $state.loaded()
        //   } else {
        //     $state.complete()
        //   }
        // }
      })
    },
    selectPortal(value) {
      const vm = this
      this.portal_id = value
      vm.page = 1
      const condition = {
        page: vm.page,
        perPage: 30,
        q: vm.q,
        sort_column: 'id',
        sort_value: 'desc',
      }

      if (value !== 0) {
        condition.portal_id = value
      }

      vm.$store.dispatch('media/FETCH', condition).then((res) => {
        if (res.data.data.length) {
          vm.mediaData = vm.medias
          vm.page++
        }
        // if ($state) {
        //   if (res.data.page === res.data.lastPage) {
        //     $state.complete()
        //   } else {
        //     $state.loaded()
        //   }
        // }
      })
    },
    fetch() {
      const vm = this
      vm.$store.dispatch('media/FETCH', {
        page: vm.page,
        q: vm.q,
        perPage: 30,
        portal_id: vm.portal_id,
        sort_column: 'id',
        sort_value: 'desc',
      }).then((res) => {
        if (res.data.data.length) {
          const output = vm.mediaData.concat(vm.medias)
          vm.mediaData = output
          vm.page++
        }
      })
    },
    updateMedia() {
      const vm = this
      vm.$store.dispatch('media/UPDATE', vm.selectedMedia.id).then((res) => {
        vm.$notification.success({
          message: 'Media Updated',
          description: 'Successfully updated.',
        })
      })
    },
    uploadMedia(data) {
      const vm = this
      this.$store.commit('media/SET_STATE', {
        loading: true,
      })
      const formData = new FormData()
      formData.append('image', data.file)
      formData.append('name', data.file.name)
      formData.append('alt', data.file.name)
      formData.append('portals[0]', this.settings.activePortal)
      return apiClient({
        method: 'post',
        url: '/v1/media',
        data: formData,
        onUploadProgress: ({ total, loaded }) => {
          data.onProgress({ percent: Number(Math.round((loaded / total) * 100).toFixed(2)) }, data.file)
        },
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then((res) => {
          data.onSuccess(res, data.file)
          // this.$set(this.form, 'image_id', res.data.data.id)
          // this.$store.dispatch('media/FETCH')
          const newFileList = this.fileList.filter((file) => file.status !== 'done')
          this.$set(this, 'fileList', newFileList)
          this.mediaData = [res.data.data].concat(this.mediaData)
          this.mediaFiles = {
            uid: data.file.uid, // The unique identifier of the file, it is recommended to set a negative number to prevent conflicts with internally generated id
            name: data.file.name, // file name
            status: 'done', // Status: uploading done error removed
            response: res, // server response content
          }
        })
        .catch((error) => {
          vm.$notification.error({
            message: 'Image upload error',
            description: error && error.response && error.response.data ? error.response.data.message : error.message,
          })
          data.onError(error)
        })
    },
    mediaUploaderChanged(info) {
      const vm = this
      vm.fileList = info.fileList
      if (!info.event) {
        vm.mediaTab = 'library'
      }
    },
    deleteMedia(id) {
      const vm = this
      Modal.confirm({
        zIndex: 2000,
        title: 'Are you sure delete this media?',
        content: 'This media will deleted permanently',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          const galleryIndex = vm.medias.map((media) => { return media.id }).indexOf(id)
          vm.medias.splice(galleryIndex, 1)
          vm.$store.dispatch('media/DELETE', id).then((res) => {
            vm.$notification.success({
              message: 'Media Deleted',
              description: 'Successfully deleted.',
            })
            vm.selectedMedia = {}
            const checkedIndex = vm.checkedMedias.map((media) => { return media.id }).indexOf(id)
            if (checkedIndex !== -1) {
              vm.checkedMedias = vm.checkedMedias.splice(checkedIndex, 1)
            }
            const newMediaData = vm.mediaData.filter((media) => {
              return media.id !== id
            })
            vm.$set(vm, 'mediaData', newMediaData)
          })
        },
        onCancel() {},
      })
    },
    selectMedia(e, item, index) {
      const vm = this
      const checkedIndex = vm.checkedMedias.map((media) => { return media.id }).indexOf(item.id)
      if (vm.multiple && ((e.ctrlKey || e.metaKey) || this.noControlPick)) { // Multiple pick
        if (checkedIndex !== -1) {
          vm.checkedMedias.splice(checkedIndex, 1)
        } else {
          vm.checkedMedias.push(item)
          vm.lastMediaIndex = index
        }
      } else if (vm.multiple && e.shiftKey) {
        if (index > vm.lastMediaIndex) {
          for (let i = vm.lastMediaIndex; i <= index; i++) {
            if (!vm.checkedMedias.find((media) => media.id === vm.medias[i].id)) {
              vm.checkedMedias.push(vm.medias[i])
            }
          }
        } else {
          for (let i = vm.lastMediaIndex; i >= index; i--) {
            if (!vm.checkedMedias.find((media) => media.id === vm.medias[i].id)) {
              vm.checkedMedias.push(vm.medias[i])
            }
          }
        }
      } else {
        if (checkedIndex !== -1 && vm.checkedMedias.length === 1) {
          vm.checkedMedias.splice(checkedIndex, 1)
        } else {
          vm.checkedMedias = [item]
          vm.lastMediaIndex = index
        }
      }
      vm.selectedMedia = vm.checkedMedias.length ? vm.checkedMedias[vm.checkedMedias.length - 1] : {}
      vm.$store.commit('media/SET_STATE', {
        form: vm.selectedMedia,
      })
    },
    insertMedia() {
      this.$emit('insert', this.selectedMedia, this.checkedMedias)
    },
    bytesToSize(bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return '0 Byte'
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
    },
  },
  watch: {
    'settings.activePortal': function($state) {
      const vm = this
      vm.mediaData = []
      vm.page = 1
      vm.$store.dispatch('media/FETCH', {
        page: vm.page,
        perPage: 30,
        q: vm.q,
        portal_id: vm.settings.activePortal,
        sort_column: 'id',
        sort_value: 'desc',
      }).then((res) => {
        vm.mediaData = vm.medias
      })
    },
  },
  components: {
    MainForm,
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
