var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form-model',{ref:"form",attrs:{"spellcheck":"false","model":_vm.form,"rules":_vm.rules},on:{"submit":function($event){$event.preventDefault();return _vm.$emit('submit')}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('a-form-model-item',{attrs:{"disabled":_vm.loading,"label":"Name","name":"name","prop":"name","label-col":{
            xs: { span: 23 },
            sm: { span: 7 },
          },"wrapper-col":{
            xs: { span: 23 },
            sm: { span: 17 },
          }}},[_c('a-input',{attrs:{"required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"disabled":_vm.loading,"label":"Description","name":"description","prop":"description","label-col":{
            xs: { span: 23 },
            sm: { span: 7 },
          },"wrapper-col":{
            xs: { span: 23 },
            sm: { span: 17 },
          }}},[_c('a-textarea',{attrs:{"required":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('a-form-model-item',{attrs:{"disabled":_vm.loading,"label":"Alt. Text","name":"alt","prop":"alt","label-col":{
            xs: { span: 23 },
            sm: { span: 7 },
          },"wrapper-col":{
            xs: { span: 23 },
            sm: { span: 17 },
          }}},[_c('a-input',{attrs:{"required":""},model:{value:(_vm.form.alt),callback:function ($$v) {_vm.$set(_vm.form, "alt", $$v)},expression:"form.alt"}})],1),_c('a-form-model-item',{attrs:{"disabled":_vm.loading,"label":"Source","name":"source","prop":"source","label-col":{
            xs: { span: 23 },
            sm: { span: 7 },
          },"wrapper-col":{
            xs: { span: 23 },
            sm: { span: 17 },
          }}},[_c('a-input',{attrs:{"required":""},model:{value:(_vm.form.source),callback:function ($$v) {_vm.$set(_vm.form, "source", $$v)},expression:"form.source"}})],1),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"px-4",attrs:{"disabled":_vm.loading,"size":"sm","variant":"info"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Save")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }