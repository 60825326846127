<template>
  <div>
    <a-form-model @submit.prevent="$emit('submit')" spellcheck="false" ref="form" :model="form" :rules="rules">
      <b-row>
        <b-col cols="12">
          <a-form-model-item
            :disabled="loading"
            label="Name"
            name="name"
            prop="name"
            :label-col="{
              xs: { span: 23 },
              sm: { span: 7 },
            }"
            :wrapper-col="{
              xs: { span: 23 },
              sm: { span: 17 },
            }">
            <a-input required v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item
            :disabled="loading"
            label="Description"
            name="description"
            prop="description"
            :label-col="{
              xs: { span: 23 },
              sm: { span: 7 },
            }"
            :wrapper-col="{
              xs: { span: 23 },
              sm: { span: 17 },
            }">
            <a-textarea required v-model="form.description" />
          </a-form-model-item>
          <a-form-model-item
            :disabled="loading"
            label="Alt. Text"
            name="alt"
            prop="alt"
            :label-col="{
              xs: { span: 23 },
              sm: { span: 7 },
            }"
            :wrapper-col="{
              xs: { span: 23 },
              sm: { span: 17 },
            }">
            <a-input required v-model="form.alt" />
          </a-form-model-item>
          <a-form-model-item
            :disabled="loading"
            label="Source"
            name="source"
            prop="source"
            :label-col="{
              xs: { span: 23 },
              sm: { span: 7 },
            }"
            :wrapper-col="{
              xs: { span: 23 },
              sm: { span: 17 },
            }">
            <a-input required v-model="form.source" />
          </a-form-model-item>
          <div class="text-right">
            <b-button :disabled="loading" class="px-4" size="sm" variant="info" @click="submit()">Save</b-button>
          </div>
        </b-col>
      </b-row>
    </a-form-model>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'mainForm',
  props: {
    type: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      rules: {
        name: [{ required: true, message: 'Please insert name' }],
        description: [{ required: true, message: 'Please insert description' }],
        alt: [{ required: true, message: 'Please insert alt text' }],
        source: [{ required: true, message: 'Please insert Source Image' }],
      },
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapState(['settings']),
    ...mapState('media', {
      form: state => state.form,
    }),
    loading() {
      return this.$store.state.tag.loading
    },
  },
  methods: {
    submit() {
      const vm = this

      vm.$refs.form.validate(valid => {
        if (valid) {
          vm.$emit('submit')
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    },
    // Media
    mediaFinderOpen() {
      const vm = this

      vm.$bvModal.show('media-finder')
    },
  },
  destroyed() {
    this.$store.commit('tag/CLEAR_DATA')
    this.$store.commit('tag/CLEAR_FORM')
  },
}
</script>

<style lang="scss">
@import "./style.module.scss";
.modal {
  // z-index: 998;
}
.editor {
  line-height: normal !important;
  .ant-form-item-control {
    line-height: normal !important;
  }
}
</style>
